@import "styles/utils/mixins";

.wrapper {
  height: 100%;
  width: 100%;
  pointer-events: auto;
  background-color: rgb(24 22 44 / 90%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.inner {
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: #fff;
  padding: 50px 75px;
  box-sizing: border-box;
  position: relative;
  margin: auto 0;
  overflow: hidden;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .close-icon {
    --native-icon-fill: var(--brand-color-key);
  }
}

.course-list {
  width: 500px;
  padding: 0;
}

@include small-screen {
  .wrapper {
    padding: 50px 30px;
  }

  .inner {
    padding: 45px 30px;
  }

  .full-area {
    padding: 0;
  }

  .course-list {
    max-width: 500px;
    width: 100%;
    padding: 0;
  }
}
