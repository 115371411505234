.light {
  --language-selector-color: #7a95cb;
  --language-selector-color-hover: white;
  --language-selector-color-active: white;
}

.dark {
  --language-selector-color: #18162c;
  --language-selector-color-hover: var(--brand-color-key);
  --language-selector-color-active: #18162c;
}
