.title {
  font-size: 28px;
  line-height: 35px;
  position: relative;
  z-index: 1;
}

.item {
  position: relative;

  .arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(51 67 128/ 60%);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(75%, -15%);
  }

  svg {
    path:first-of-type {
      stroke: var(--text-color-light);
    }

    path:last-of-type {
      fill: var(--text-color-light);
    }
  }
}

.item-link:not(:last-child) {
  display: block;
  border-bottom: 1px solid rgba(51 67 128 / 60%);
}

.item-title {
  font-size: 28px;
  line-height: 35px;
  padding: 13px 0;
}

.container {
  padding-top: 66px;
  z-index: 100;
}

.content {
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #012f6b;
  padding: 18px 30px 140px;

  .button-back {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .arrow {
    display: flex;
    align-items: center;
    height: auto;
    transform: translate(-5px, 0);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgba(122 149 203 /90%);

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.back {
  line-height: 13px;
  color: rgba(122 149 203 / 90%);
}
