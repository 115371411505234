@import "styles/utils/mixins";

.article {
  padding: 42px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 23px;
    left: 23px;
    height: 2px;
    background: #dfe0e7;
  }

  &:hover {
    background-color: #dfe0e7;
  }
}

.text {
  padding: 0 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 100px;
}

.media {
  padding: 0 23px;
}

.image {
  width: 355px;
  height: 216px;
  object-fit: cover;
}

@include small-screen {
  .article {
    flex-direction: column;
    padding: 14px 0;

    &::before {
      right: 0;
      left: 0;
    }
  }

  .text {
    padding: 0;
  }

  .title {
    margin-bottom: 10px;
  }

  .media {
    padding: 0;
    display: flex;
    order: -1;
    margin-bottom: 10px;
  }

  .image {
    width: 100%;
    height: 180px;
  }
}
