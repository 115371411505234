.wrapper {
  background-color: #fff;
  width: 330px;
  height: auto;

  &:not(:last-child) {
    border-bottom: 1px solid #efeff3;
  }
}

.link {
  position: relative;
  display: block;
  padding: 24px 32px;

  p {
    font-size: 18px;
    line-height: 22px;
    transition: all 0.4s;
  }

  &:hover p {
    color: var(--text-color-brand);
    transition: all 0.4s;
  }
}
