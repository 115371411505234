@import "styles/utils/mixins";

.wrapper {
  padding: 30px 40px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 23px;
}

.caption {
  line-height: 15px;
  color: rgba(24 22 44/ 100%);
  font-weight: 500;
  white-space: nowrap;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--text-color-brand);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  flex: none;

  path:first-of-type {
    stroke: var(--text-color-light);
  }

  path:last-of-type {
    fill: var(--text-color-light);
  }
}

@include small-screen {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 25px 25px;
    position: relative;
    display: flex;

    &:first-of-type() {
      border-right: 1px solid rgba(51 67 128 / 60%);
    }
  }

  .title {
    margin-bottom: 24px;
    color: var(--text-color-light);
    max-width: 90%;
  }

  .button {
    width: 30px;
    height: 30px;
    background-color: var(--text-color-light);
    position: absolute;
    bottom: 25px;
    right: 25px;
  }

  .icon {
    path:first-of-type {
      stroke: var(--text-color-dark);
    }

    path:last-of-type {
      fill: var(--text-color-dark);
    }
  }
}
