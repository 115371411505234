@import "styles/utils/mixins";
@import "styles/variables/typography";

.details {
  margin-bottom: 40px;
}

.text {
  font-family: $font-main;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

.label {
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 5px;
}

@include small-screen {
  .details {
    margin-bottom: 34px;
  }

  .text {
    font-size: 16px;
    line-height: 20px;
  }
}
