@import "styles/utils/mixins";

.wrapper {
  max-width: 100%;
  flex: 1;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  display: grid;
  grid-template-columns: 100px minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  padding-bottom: 45px;
  position: relative;

  &:not(:first-child) {
    padding-top: 45px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #c8c7cf;
  }
}

.description {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-top: 16px;
}

.title.small {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.title.bigger {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.title.bold {
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
}

@include small-screen {
  .wrapper {
    margin-top: 30px;
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list-item {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    padding-bottom: 25px;

    &:not(:first-child) {
      padding-top: 25px;
    }
  }

  .description {
    grid-column-end: 3;
    margin-top: 24px;
  }

  .title.bigger,
  .title.bold {
    font-size: 16px;
    line-height: 20px;
  }
}
