@import "styles/utils/mixins";

.wrapper {
  position: relative;
  background-color: #fff;
  padding: 42px 86px;
  overflow: hidden;
  border: 1px solid #efeff3;
  z-index: 1;
}

.blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 30px;
  line-height: 38px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
  max-width: 360px;
  color: #18162c;
  padding-right: 86px;
}

.subscribe {
  flex: 1;
}

.form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-group {
  flex: 1;
}

.circles {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid rgba(206 16 80 / 40%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;
    width: 452px;
    height: 452px;
    border-radius: 50%;
    border: 1px solid rgb(206 16 80 / 10%);
    position: absolute;
  }
}

.arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ce1050;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;

  path:first-of-type {
    stroke: #fff;
  }

  path:last-of-type {
    fill: #fff;
  }
}

.modified {
  padding: 40px 50px;
  min-height: 200px;
  width: 100%;

  .blocks {
    flex-direction: column;
    align-items: flex-start;
  }

  .subscribe {
    width: 100%;
  }

  .title {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    max-width: 100%;
    margin: 0 0 15px;
    color: #8b8b95;
    padding-right: 0;
  }

  .input-group {
    max-width: 70%;
  }

  .circles {
    width: 0;
    height: 0;
    border: 0;

    &::after {
      content: none;
    }
  }

  .arrow {
    transform: translate(-35%, -25%);
  }
}

@include small-screen {
  .wrapper {
    padding: 35px 30px;
  }

  .blocks {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 27px;
  }

  .subscribe {
    width: 100%;
  }

  .input-group {
    flex: 1;
    width: 100%;
  }

  .circles {
    width: 0;
    height: 0;
    border: 0;

    &::after {
      content: none;
    }
  }

  .arrow {
    width: 40px;
    height: 40px;
    top: -50px;
    right: 0;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .modified {
    padding: 35px 30px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      color: var(--text-color-dark);
      margin: 0 0 30px;
    }

    .input-group {
      max-width: 100%;
    }

    .arrow {
      transform: translate(0, 0);
    }
  }
}
