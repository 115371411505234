@import "styles/utils/mixins";
@import "styles/variables/typography";

.element {
  font-family: $font-text;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.appearance-bold {
  font-weight: 700;
}

.appearance-bigger {
  font-weight: 600;
  font-size: 14px;
}

.appearance-smaller {
  font-size: 12px;
  line-height: 18px;
}

@include small-screen {
  .element {
    font-size: 12px;
    line-height: 20px;
  }

  .appearance-bold {
    font-size: 13px;
  }

  .appearance-bigger {
    font-size: 12px;
  }

  .appearance-smaller {
    font-size: 11px;
    line-height: 16px;
  }
}
