.wrapper {
  display: block;
  width: 100%;
  height: 90px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}

.inner {
  height: 100%;
  display: grid;
  grid-template-columns: 65px auto auto 44px;
  grid-gap: 55px;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

@media (max-width: 1230px) {
  .inner {
    grid-gap: 20px;
  }

  .actions {
    gap: 20px;
  }
}
