.active {
  background-color: #efeff3;
}

.container {
  padding-top: 66px;
  z-index: 99;
}

.content {
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #012f6b;
  padding: 43px 15px;
}

.signin {
  margin-bottom: 48px;

  span {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
    text-transform: none;
  }
}
