@import "styles/utils/mixins";

.element {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
}

@include small-screen {
  .element {
    font-size: 16px;
    line-height: 20px;
  }
}
