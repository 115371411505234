@import "styles/utils/mixins";

.label {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  padding-left: 24px;
  position: relative;
  color: #8b8b95;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  left: 0;
  top: 2px;
  border-radius: 2px;
  border: 1px solid #8b8b95;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 3px;
    width: 3px;
    height: 6px;
    border-right: 1px solid var(--text-color-brand);
    border-bottom: 1px solid var(--text-color-brand);
    transform: rotate(45deg);
    transition: all 0.4s;
    opacity: 0;
  }

  &--required {
    border: 1px solid var(--text-color-brand);
  }
}

.checkbox {
  position: relative;
  display: none;

  &:checked + span::after {
    opacity: 1;
  }
}

@include small-screen {
  .checkmark {
    top: 2px;
  }
}
