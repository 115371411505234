@import "styles/utils/mixins";

.wrapper {
  max-width: 100%;
  flex: 1;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  align-items: center;
  padding-bottom: 45px;
  position: relative;

  &:not(:first-child) {
    padding-top: 45px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #c8c7cf;
  }
}

.description {
  grid-column-start: 2;
  grid-column-end: 3;
  display: none;
  max-height: 0;
  margin-top: 0;
}

.title {
  max-width: 90%;
}

.subtitle {
  padding-right: 50px;
}

.title.bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.dropdown {
  transition: all 0.4s;
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #c8c7cf;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, 50%);
}

.list-item:first-child .dropdown {
  transform: translate(0, -25%);
}

.icon {
  transition: all 0.4s;

  path {
    fill: #acacb4;
  }
}

.circle {
  transition: all 0.4s;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ce1050;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 14px;
    height: 2px;
    background: #ce1050;
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    height: 14px;
    background: #ce1050;
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  .icon {
    transform: rotate(180deg);
  }

  .description {
    display: block;
    margin-top: 30px;
    max-height: 100%;
    transition: max-height 0.1s ease-in-out;
  }

  .circle {
    border: 1px solid #ce1050;
    background-color: #ce1050;

    &::after {
      background: #fff;
      transition: all 0.5s ease-in-out;
      transform: rotate(180deg);
    }

    &::before {
      background: #fff;
      transition: all 0.5s ease-in-out;
      transform: rotate(180deg);
      opacity: 0;
    }
  }
}

@include small-screen {
  .wrapper {
    max-width: 100%;
    margin-top: 30px;
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list-item {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    padding-bottom: 45px;
  }

  .dropdown {
    transform: translate(0, 75%);
  }

  .list-item:first-child .dropdown {
    transform: translate(0, -7%);
  }

  .description {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .title {
    max-width: 80%;
  }

  .title.bold {
    font-size: 16px;
    line-height: 20px;
  }
}
