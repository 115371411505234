@import "styles/utils/mixins";

.title {
  margin-bottom: 60px;
}

.registration {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  label {
    padding-top: 20px;

    span {
      top: 22px;
    }
  }
}

.form {
  width: 60%;
  flex: 1;
}

.input-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.submit {
  display: flex;
  align-items: center;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ce1050;
  margin-left: 28px;

  &::after,
  &::before {
    transform: none;
  }

  path:first-of-type {
    stroke: #fff;
  }

  path:last-of-type {
    fill: #fff;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

@include small-screen {
  .title {
    margin-bottom: 30px;
  }

  .registration {
    flex-direction: column;
    gap: 0;

    label {
      padding-top: 0;
      margin-bottom: 30px;

      span {
        top: 2px;
      }
    }
  }

  .form {
    width: 100%;
  }

  .input-group {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
