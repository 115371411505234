@import "styles/utils/mixins";

.element {
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 130px;
}

@include small-screen {
  .element {
    font-size: 55px;
    line-height: 64px;
  }
}
