@import "styles/utils/mixins";

.teachers {
  .teacher {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    text-decoration: none;
    cursor: pointer;

    .timing {
      font-size: 16px;
      font-weight: 700;
      color: var(--text-color-grey);
      position: absolute;
      left: -22%;
    }

    p.title {
      margin-bottom: 2px;
      font-family: "TT Interfaces", sans-serif;
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
    }

    p.description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--text-color-grey);
      max-width: 80%;
      margin-bottom: 0;
    }

    .image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-right: 18px;
      object-fit: cover;
    }
  }
}

@include small-screen {
  .teacher {
    p.title {
      font-size: 14px;
      line-height: 18px;
    }

    p.description {
      max-width: 90%;
    }
  }
}
