@import "styles/utils/mixins";

.sticky {
  position: sticky;
  top: 100px;
  max-width: 360px;
}

@include small-screen {
  .sticky {
    position: relative;
    top: auto;
    max-width: 100%;
  }
}
