@import "styles/utils/mixins";

.wrapper {
  position: relative;
  cursor: pointer;
  margin-left: -20px;
}

.list {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 25px 0 15px;
  gap: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
}

.item {
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--language-selector-color);

  &:not(.active):hover {
    color: var(--language-selector-color-hover);
  }
}

.icon {
  transform-origin: 50% 50%;
}

.opened {
  .icon {
    transform: rotate(180deg);
  }
}

@include small-screen {
  .icon {
    display: none;
  }
}
