@import "styles/utils/mixins";

.wrapper {
  height: 100%;
  width: 80%;
  margin-left: auto;
  background: #18162c;
  padding: 84px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 33px;
}

.text {
  margin-top: 43px;
}

.author {
  margin-bottom: 8px;
}

.quote span {
  width: 45px;
  height: 38px;
}

@include small-screen {
  .wrapper {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 83px 30px;
  }

  .image {
    width: 50px;
    height: 50px;
    margin-bottom: 0;
    margin-right: 24px;
  }

  .quote {
    margin-bottom: 60px;

    span {
      width: 35px;
      height: 20px;
    }
  }

  .person {
    display: flex;
  }

  .author {
    margin-bottom: 4px;
  }
}
