.wrapper {
  background-color: #012f6b;
  padding: 30px;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  border-bottom: 1px solid #334380;
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 35px 0;
}

.links {
  width: 100%;
  max-width: 260px;
}

.info {
  display: grid;
  grid-template-columns: 55px 1fr;
  padding: 30px 0;
  gap: 15px;
}

.logo {
  padding-right: 20px;
  border-right: 1px solid #334380;
}

.label {
  opacity: 0.6;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
}

.brands {
  display: flex;
  align-items: center;

  p {
    line-height: 15px;
  }
}

.ampersand {
  padding: 0 5px;
}

.credentials {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 20px;
  padding: 30px 0 0;
}

.right,
.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

.interactive {
  gap: 0;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #334380;
  }
}

.item {
  color: rgba(122 149 203 / 90%);
  font-weight: 600;

  &:hover {
    color: var(--text-color-light);
  }
}

.interactive .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: var(--text-color-light);
  text-transform: uppercase;
}

.icon {
  transition: transform 0.1s linear;
}

.opened {
  .icon {
    transform: rotate(180deg);
    transition: transform 0.1s linear;
  }

  & + .list {
    margin-top: 10px;
    display: grid;
    gap: 10px;
  }
}
