.element {
  font-family: "TT Interfaces", sans-serif;
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.color-dark {
  color: var(--text-color-dark);
}

.color-light {
  color: var(--text-color-light);
}

.color-grey {
  color: var(--text-color-grey);
}

.color-brand {
  color: var(--text-color-brand);
}

.color-blue {
  color: var(--text-color-blue);
}
