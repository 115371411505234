@import "styles/utils/mixins";

.element {
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  line-height: 96px;
}

@include small-screen {
  .element {
    font-size: 36px;
    line-height: 40px;
  }
}
