.link {
  display: flex;
  align-items: center;
  height: 100%;
  border-top: 2px solid transparent;
  transition: all 0.4s;

  &:hover {
    border-top: 2px solid var(--text-color-brand);
    transition: all 0.4s;
  }
}

.title {
  font-weight: 600;
  transition: all 0.4s;
}

.item {
  height: inherit;
  position: relative;

  &:hover .title {
    color: var(--text-color-brand);
    transition: all 0.4s;
  }
}

.overlay {
  width: 100%;
  height: calc(100vh - 90px);
  background-color: rgba(#18162c, 0.8);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 90px;
}

.dropdown {
  width: 100%;
  pointer-events: auto;
  position: fixed;
  left: auto;
  top: 90px;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
  border-top: 1px solid #efeff3;
}
