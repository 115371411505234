.pagination-current {
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 1px;
    background-color: #8b8b95;
    margin-left: 7px;
  }
}
