.list {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.item {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--language-selector-color);

  &:hover {
    color: var(--language-selector-color-hover);
  }
}

.active {
  color: var(--language-selector-color-active);
}
