@import "styles/utils/mixins";

.info {
  width: 35%;
  padding-top: 10px;
}

.item {
  margin-bottom: 35px;
}

.info-label {
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 5px;
}

.info-text {
  font-family: "TT Interfaces", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

@include small-screen {
  .info {
    width: 100%;
    padding-top: 0;
  }

  .info-label {
    margin-bottom: 0;
  }

  .info-text {
    font-size: 14px;
    line-height: 18px;
  }
}
