@import "styles/utils/mixins";

.article {
  padding: 60px 120px 60px 70px;
  margin-bottom: 50px;
  position: relative;
  background-color: #012f6b;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(24 22 44 / 10%) 0%,
      rgba(24 22 44 / 70%) 100%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 1;
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 2;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  width: 65%;
}

.title {
  font-family: "TT Interfaces", sans-serif;
  margin-bottom: 40px;
}

.date {
  font-family: "TT Interfaces", sans-serif;
  font-size: 20px;
  margin-bottom: 60px;
}

.link {
  @include circles(242px, 552px, 24px);

  &::after,
  &::before {
    border: 1px solid rgb(255 255 255 / 40%);
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #ce1050;
  margin-right: 30px;

  path:first-of-type {
    stroke: #fff;
  }

  path:last-of-type {
    fill: #fff;
  }
}

@include small-screen {
  .article {
    min-height: 420px;
    padding: 26px 20px 47px;
    margin-bottom: 39px;
    overflow: hidden;

    &::before {
      background: linear-gradient(
        180deg,
        rgba(24 22 44 / 15%) 39.1%,
        rgba(24 22 44 / 80%) 86.49%
      );
    }
  }

  .card {
    flex-direction: column;
    align-items: flex-start;
  }

  .text {
    width: 100%;
  }

  .link {
    @include circles(140px, 320px, 16px);

    &::after,
    &::before {
      border: 1px solid rgb(255 255 255 / 40%);
    }
  }

  .arrow {
    width: 50px;
    height: 50px;
    margin-right: 17px;
  }
}
