.nav {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.nav-mobile {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 40px;
}

@media (max-width: 1230px) {
  .nav {
    gap: 15px;
  }
}
