.wrapper {
  background-color: #fff;
  max-width: 1290px;
  margin: 0 auto;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 420px;
}

.groups {
  display: block;
  column-count: 2;
}

.group {
  width: 100%;
  display: inline-block;
  break-inside: avoid-column;
  border-right: 1px solid #efeff3;
  border-bottom: 1px solid #efeff3;

  &:nth-child(2),
  &:nth-child(3) {
    border-bottom-color: transparent;
  }

  &:nth-child(3) {
    border-color: transparent;
  }
}

.title {
  margin-bottom: 33px;
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 30px;
}

.paper {
  padding: 36px 88px 54px 48px;
}

.divider {
  border-bottom: 1px solid #efeff3;
}

.item {
  padding-left: 14px;
  border-left: 2px solid #18162c;

  &:hover {
    transition: all 0.4s;
    border-left: 2px solid var(--text-color-brand);
  }
}

.link:hover p {
  transition: all 0.4s;
  color: var(--text-color-brand);
}

.subtitle {
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 23px;
}

.caption {
  line-height: 15px;
  color: rgba(24 22 44/ 50%);
  font-weight: 500;
  white-space: nowrap;

  &-general {
    color: rgba(24 22 44/ 100%);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #efeff3;

  .paper:first-child {
    margin-bottom: auto;
  }
}
