@import "styles/utils/mixins";

.input-type {
  margin-bottom: 11px;
  position: relative;

  input:invalid[focused="true"] {
    border-bottom: 2px solid var(--text-color-brand);
  }
}

.input {
  width: 100%;
  display: block;
  color: #18162c;
  font-size: 14px;
  font-weight: 600;
  padding: 11px 0;
  border-bottom: 2px solid #dfe0e7;

  &::placeholder {
    color: #18162c;
    font-size: 14px;
    font-weight: 600;
  }
}

.error {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-color-brand);
}

.has-error {
  border-bottom: 2px solid var(--text-color-brand);
}

@include small-screen {
  .input-type {
    margin-bottom: 15px;
  }

  .error {
    right: 15%;
  }
}
