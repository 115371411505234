@import "styles/utils/mixins";

.wysiwyg {
  h1 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 120px;
    line-height: 130px;
    font-weight: 700;
    margin-bottom: 34px;
  }

  h2 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 88px;
    line-height: 96px;
    font-weight: 700;
    margin-bottom: 34px;
  }

  h3 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 45px;
    line-height: 57px;
    font-weight: 700;
    margin-bottom: 34px;
  }

  h4 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 34px;
  }

  h5 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 34px;
  }

  h6 {
    font-family: "TT Interfaces", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 34px;
  }

  p {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }

  a {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #ce1050;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  ul {
    li {
      position: relative;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding-left: 40px;

      &::before {
        content: " ";
        position: absolute;
        top: 12px;
        left: 0;
        width: 16px;
        height: 2px;
        background: #012f6b;
      }
    }
  }

  ol {
    list-style: decimal;

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      list-style-position: inside;
    }
  }

  blockquote {
    position: relative;
    background-color: var(--text-color-dark);
    color: var(--text-color-light);
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    padding: 45px 48px 45px 72px;
    margin: 20px 0;

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% - 90px);
      transform: translate(-14px, 0%);
      background-color: var(--text-color-brand);
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
@include small-screen {
  .wysiwyg {
    h1 {
      font-size: 55px;
      line-height: 64px;
      margin-bottom: 22px;
    }

    h2 {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 22px;
    }

    h3 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 22px;
    }

    h4 {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 22px;
    }

    h5 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 22px;
    }

    h6 {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 22px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    a {
      font-size: 12px;
      line-height: 20px;
    }

    ul {
      li {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 25px;
        margin-bottom: 24px;

        &::before {
          width: 11px;
        }
      }
    }

    blockquote {
      font-size: 13px;
      line-height: 20px;
      padding: 42px 31px 42px 42px;

      &::before {
        height: calc(100% - 84px);
        transform: translate(-12px, 0%);
      }
    }
  }
}

.color-dark {
  color: var(--text-color-dark);
}

.color-light {
  color: var(--text-color-light);
}

.color-grey {
  color: var(--text-color-grey);
}

.color-brand {
  color: var(--text-color-brand);
}
