@import "styles/utils/mixins";

.element {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 57px;
}

@include small-screen {
  .element {
    font-size: 24px;
    line-height: 30px;
  }
}
