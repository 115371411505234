@import "styles/utils/mixins";

.title {
  font-size: 15px;
  margin-bottom: 15px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}

.active {
  color: var(--text-color-brand);
}

.link {
  display: flex;
  align-items: center;
  margin: 3px;
  border: 1px solid #dfe0e7;
  border-radius: 40px;
}

.tag {
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  padding: 10px 17px;
}

.button {
  margin-top: 15px;
}

.arrow {
  display: flex;
  align-items: center;
}

.label {
  font-size: 16px;
  margin-right: 22px;
}

.icon {
  width: 16px;
  height: 16px;

  path:first-of-type {
    stroke: var(--text-color-brand);
  }

  path:last-of-type {
    fill: var(--text-color-brand);
  }
}

.heading {
  margin-bottom: 35px;
}

@include small-screen {
  .title {
    font-size: 13px;
  }

  .tag {
    font-size: 10px;
    line-height: 12px;
    padding: 12px 17px;
  }
}
