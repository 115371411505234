.wrapper {
  background-color: #012f6b;
  padding: 80px 0 44px;
}

.row {
  display: grid;
  grid-template-columns: 120px 170px 220px repeat(2, minmax(0, 175px));
  justify-content: space-between;
}

.col {
  display: flex;
  flex-direction: column;
}

.links {
  padding-bottom: 90px;
  border-bottom: 1px solid rgba(#fff, 0.1);
}

.info {
  padding-top: 45px;
}

.links .col {
  justify-content: space-between;
}

.info .col {
  gap: 5px;
}

.link {
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.label {
  opacity: 0.6;
}

.selector {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.item {
  color: rgba(122 149 203 / 90%);
  font-weight: 600;

  &:hover {
    color: var(--text-color-light);
  }
}

.title {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: var(--text-color-light);
  text-transform: uppercase;
}
