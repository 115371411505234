.icon {
  width: 60px;
  height: 60px;
  margin-right: 25px;
}

.download {
  display: flex;
  align-items: center;
  width: 216px;
  margin-top: 22px;
  font-size: 14px;
  line-height: 18px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
}
