.image {
  width: 16px;
  height: 16px;
  display: block;
  object-fit: contain;
  object-position: center;

  > * {
    width: 100%;
    height: 100%;
  }
}

.white {
  --native-icon-fill: white;
  --native-icon-secondary-fill: black;
}

.grey {
  --native-icon-fill: #8b8b95;
  --native-icon-secondary-fill: #18162c;
}

.dark {
  --native-icon-fill: #18162c;
}

.accent {
  --native-icon-fill: var(--brand-color-key);
}
