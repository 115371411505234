@import "styles/utils/mixins";

.wrapper {
  padding: 0 105px 0 60px;
}

.blocks {
  display: flex;
  align-items: center;
}

.title {
  text-align: center;
  width: 52%;
}

.circles {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 376px;
  height: 376px;
  border-radius: 50%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    opacity: 0.3;
    border-radius: 50%;
    pointer-events: none;
  }

  &::before {
    height: 786px;
    width: 786px;
  }

  &::after {
    height: 1424px;
    width: 1424px;
  }
}

.circle {
  position: absolute;
  height: 184px;
  width: 184px;
  border-radius: 50%;
}

.icon {
  position: relative;
  z-index: 1;
}

.successful {
  .circles {
    border: 1px dashed #012f6b;

    &::before,
    &::after {
      border: 1.075px dashed #7a95cb;
    }
  }

  .circle {
    background: #012f6b;
  }

  .icon {
    height: 33.5px;
    width: 56px;
  }
}

.error {
  .circles {
    border: 1px dashed var(--text-color-brand);

    &::before,
    &::after {
      border: 1.075px dashed var(--text-color-brand);
    }
  }

  .circle {
    background: #012f6b;
  }

  .icon {
    height: 60px;
    width: 60px;

    path {
      fill: #fff;
    }
  }
}

@include small-screen {
  .wrapper {
    padding: 0;
  }

  .blocks {
    flex-direction: column;
    justify-content: center;
  }

  .title {
    width: 100%;
    margin-bottom: 5px;
  }

  .circles {
    width: 198px;
    height: 198px;

    &::before {
      height: 348px;
      width: 348px;
    }

    &::after {
      display: none;
    }
  }

  .circle {
    height: 98px;
    width: 98px;
  }

  .successful {
    .icon {
      height: 18px;
      width: 30px;
    }
  }

  .error {
    .icon {
      height: 30px;
      width: 30px;
    }
  }
}
