@import "styles/utils/mixins";

.block {
  display: block;
  padding: 42px 73px 42px 23px;
  background-color: var(--text-color-blue);
  position: relative;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  width: 80%;
}

.heading {
  margin-bottom: 24px;
}

.description {
  margin-bottom: 27px;
  width: 85%;

  span {
    font-weight: 700;
  }
}

.button {
  display: flex;
  align-items: center;
}

.arrow {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: var(--text-color-brand);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;

  path:first-of-type {
    stroke: var(--text-color-light);
  }

  path:last-of-type {
    fill: var(--text-color-light);
  }
}

.date {
  font-size: 20px;
  font-family: "TT Interfaces", sans-serif;
}

.more {
  margin-left: 20px;
}

@include small-screen {
  .block {
    padding: 33px 30px;
  }

  .text {
    width: 100%;
  }

  .heading {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 25px;
    width: 100%;

    span {
      font-weight: 400;
    }
  }
}
