.list {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.button {
  flex: none;
}

.title {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #c8c7cf;
  margin-bottom: 13px;
  font-weight: 600;
}
