.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.groups {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 130px;
}

.group:not(:last-child) {
  border-bottom: 1px solid rgba(51 67 128 / 60%);
}

.label {
  font-size: 28px;
  line-height: 35px;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items {
  display: grid;
  gap: 20px;
}

.item {
  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 37px;
  }
}

.title {
  font-size: 18px;
  line-height: 22px;
}

.caption {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255 255 255 / 50%);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  border-top: 1px solid rgba(51 67 128 / 60%);
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #012f6b;
}

.toggle {
  transition: all 0.2s;
  position: relative;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 14px;
    height: 2px;
    background: rgba(255 255 255);
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    height: 14px;
    background: rgba(255 255 255);
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.disabled {
  pointer-events: none;

  .label {
    color: rgba(255 255 255 / 50%);
  }

  .toggle {
    &::after {
      background: rgba(255 255 255 / 50%);
    }

    &::before {
      background: rgba(255 255 255 / 50%);
    }
  }
}

.opened {
  .toggle {
    &::after {
      background: #fff;
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
    }

    &::before {
      background: #fff;
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
      opacity: 0;
    }
  }
}

.divider {
  border-right: 1px solid rgba(51 67 128 / 60%);
}
