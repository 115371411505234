.wrapper {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 40px minmax(0, 1fr);
  grid-gap: 8px;
  align-items: center;
  height: 66px;
  padding: 0 18px;
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  background: white;
}

.left {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
