.link {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 30px 40px;
  flex: 1;
}

.title {
  margin-bottom: 20px;
}

.button {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid var(--text-color-brand);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;

  path:first-of-type {
    stroke: var(--text-color-brand);
  }

  path:last-of-type {
    fill: var(--text-color-brand);
  }
}
