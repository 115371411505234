@import "styles/utils/mixins";

.teacher {
  position: relative;

  .name {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 24px;
    color: var(--text-color-light);
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      color: var(--text-color-light);
      opacity: 1;
    }
  }

  .tooltip {
    padding: 17px 23px 27px;
    background-color: #fff;

    .image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-right: 9px;
      object-fit: cover;
    }

    p.title {
      margin-bottom: 9px;
      font-family: "TT Interfaces", sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: var(--text-color-dark);
    }

    p.description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 20px;
      color: var(--text-color-grey);
    }

    .arrow {
      display: flex;
      align-items: center;

      p {
        font-size: 12px;
        line-height: 18px;
        color: var(--text-color-brand);
        margin: 0;
      }
    }
  }

  .link {
    position: absolute;
    text-decoration: none;
    top: -20px;
    right: 10%;
    max-width: 250px;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-right: 30px solid #fff;
      position: absolute;
      top: 10px;
      left: -15px;
    }
  }

  a {
    text-decoration: none;
  }
}

.icon {
  margin-left: 18px;

  svg {
    width: 16px;
    height: 16px;
  }

  path:first-of-type {
    stroke: var(--text-color-brand);
  }

  path:last-of-type {
    fill: var(--text-color-brand);
  }
}

@include small-screen {
  .teacher {
    p.title {
      font-size: 14px;
      line-height: 18px;
    }

    .link {
      top: -30px;
      right: -30px;
      max-width: 200px;
      z-index: 1;
    }
  }
}
