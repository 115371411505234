.wrapper {
  position: relative;
  width: auto;
  height: auto;
}

.button {
  position: relative;
  z-index: 1;
}

.list {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.icon {
  background-color: white;
}
