.button {
  width: auto;
  height: 44px;
  font-family: Inter, sans-serif;
  position: relative;
  border-radius: 22px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  appearance: none;

  &.outlined {
    border: 1px solid currentcolor;
  }

  &.no-text {
    width: 44px;
    padding: 0;
    border-radius: 50%;

    .icon {
      margin: 0 auto;
    }
  }

  &.no-icon {
    gap: 0;
  }

  &.outlined:not(.no-text) {
    padding: 0 20px;
  }

  &.dark {
    --native-icon-fill: #18162c;

    color: #18162c;
  }

  &.dark-reverse {
    --native-icon-fill: white;

    color: #18162c;
    background-color: #18162c;
  }

  &.light {
    --native-icon-fill: #fff;

    color: white;
  }

  &.brand {
    --native-icon-fill: var(--brand-color-key);

    color: white;
  }

  // hovers
  &.highlight:hover {
    color: var(--brand-color-key);

    .icon {
      --native-icon-fill: var(--brand-color-key);
    }
  }

  &.light.reverse:hover {
    background-color: #18162c;
    border-color: #18162c;

    .icon {
      --native-icon-fill: #fff;
    }
  }

  &.dark.reverse:hover {
    background-color: #fff;
    border-color: #fff;

    .icon {
      --native-icon-fill: #18162c;
    }
  }

  &.dark.fill:hover {
    background-color: #18162c;

    .icon {
      --native-icon-fill: #fff;
    }
  }

  &.light.fill:hover {
    color: #18162c;
    background-color: #fff;
    border-color: #fff;

    .icon {
      --native-icon-fill: #18162c;
    }
  }

  &.brand.reverse:hover {
    color: #18162c;
    background-color: var(--brand-color-key);
    border-color: var(--brand-color-key);

    .icon {
      --native-icon-fill: #fff;
    }
  }
}

.text {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
