@mixin small-screen() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin large-screen() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin circles($small, $large, $icon) {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: $small;
    height: $small;
    border: 1px solid rgb(206 16 80 / 20%);
    border-radius: 50%;
    transform: translate(-34%, 0);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: $large;
    height: $large;
    border-radius: 50%;
    border: 1px solid rgb(206 16 80 / 10%);
    transform: translate(-42%, 0);
  }

  .icon {
    width: $icon;
    height: $icon;
    margin: auto;
  }
}
