@import "styles/utils/mixins";

.wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 44%;
}

.inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    135deg,
    rgb(19 8 52 / 30%) 0%,
    rgb(19 8 52 / 0%) 99.35%
  );

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: linear-gradient(
      135deg,
      rgb(19 8 52 / 30%) 0%,
      rgb(19 8 52 / 0%) 99.35%
    );
  }
}

.content {
  position: relative;
  z-index: 3;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 85px;
  padding-bottom: 85px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.image {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  padding: 0;
  border: medium none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
}

.default {
  justify-content: flex-start;
}

.accent {
  justify-content: flex-end;

  .title::before {
    content: "";
    display: block;
    width: 54px;
    height: 4px;
    background: var(--brand-color-key);
    margin-bottom: 12px;
  }
}

@include small-screen {
  .wrapper {
    padding-bottom: 100%;
  }

  .content {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
