.wrapper {
  width: 100%;
  height: auto;
  display: block;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}
