@import "styles/utils/mixins";

.wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 44%;
}

.tabs {
  width: 50%;
  overflow: visible;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabs-list {
  display: inline-flex;
  position: relative;
  border-top: 1px solid #c8c7cf;
  margin-top: 50px;
  width: auto;
  min-width: 100%;
}

.tabs-item {
  font-family: "TT Interfaces", sans-serif;
  margin-right: 80px;
  white-space: nowrap;
  cursor: pointer;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #8b8b95;
    padding: 67.5px 0 0;
    border-top: 1px solid transparent;
    transition: 0.4s ease;
  }

  &-active p {
    color: #ce1050;
  }
}

.underline {
  content: " ";
  position: absolute;
  display: block;
  top: -1px;
  height: 1px;
  background: #ce1050;
  transition: all 500ms ease-in-out;
}

.tab-years {
  width: 100%;

  .tabs {
    width: 100%;
    overflow: visible;
    overflow-y: auto;
  }

  .tabs-list {
    display: flex;
    align-items: center;
    border-top: none;
    margin-top: 0;
  }

  .tabs-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 140px;
    border-radius: 50px;
    border: 1px solid transparent;
    margin-right: 0;
    cursor: pointer;

    &-active {
      border: 1px solid rgb(206 16 80 / 20%);
    }

    p {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding: 0;
      border-top: none;
      color: #18162c;
    }

    &-active p {
      color: #ce1050;
    }
  }

  .underline {
    content: none;
  }
}

.justify {
  justify-content: space-between;

  & li:last-of-type {
    margin-right: 0;
  }
}

@include small-screen {
  .tabs {
    width: 100%;
  }

  .tabs-list {
    justify-content: space-between;
    border-top: none;
    border-bottom: 1px solid #c8c7cf;
    margin-top: 0;
    margin-bottom: 18px;
  }

  .tabs-item {
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    & p {
      padding: 0 0 18px;
    }
  }

  .underline {
    top: auto;
    bottom: -1px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .tab-years {
    .tabs-list {
      border-bottom: none;
      justify-content: normal;
      gap: 10px;
    }

    .tabs-item {
      border-radius: 100px;

      p {
        font-size: 11px;
        line-height: 28px;
        color: #c8c7cf;
        padding: 0 10px;
      }

      &-active p {
        color: #ce1050;
      }
    }

    .underline {
      display: none;
    }
  }
}
